import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import * as articlesActions from '../actions/ArticlesActions';
import * as actions from '../actions/ContentsActions';
import BaseTable from '../components/table/BaseTable';
import Toast from '../components/Toast';
import * as articlesReducers from '../reducers/ArticlesReducers';
import * as reducers from '../reducers/ContentsReducers';
import * as schemas from '../schemas';

class Contents extends React.Component {
  constructor(props) {
    super(props);
    this.columns = [
      { label: 'Article', name: 'articleId', default: model => model.article.title },
      { label: 'Body', name: 'body', default: this.defaultBody }
    ];
    this.fields = [
      {
        type: 'dropdown',
        errorMessages: [
          'The article field is required.'
        ],
        label: 'Article',
        name: 'articleId',
        options: options => options.articlesOptions.map(o => ({ text: o.title, value: o.id })),
        search: true,
        validators: ['required']
      },
      {
        type: 'textarea',
        errorMessages: [
          'The body field is required.'
        ],
        label: 'Body',
        name: 'body',
        validators: ['required']
      }
    ];
    this.modelName = 'content';
  }

  UNSAFE_componentWillMount() {
    const { data } = this.props;
    if (!data.length) {
      this.fetchContents();
      this.fetchArticlesOptions();
    }
  }

  fetchContents = ({ page, perPage, sort, sortDirection } = {}) => {
    const { fetchContents, paginationSettings } = this.props;
    return fetchContents({
      page: page || paginationSettings.page,
      perPage: perPage || paginationSettings.perPage,
      sort: sort || paginationSettings.sort,
      sortDirection: sortDirection || paginationSettings.sortDirection
    })
      .catch(() => this.openToastFailure('Contents fetching failed'));
  };

  fetchArticlesOptions = () => {
    const { fetchArticlesOptions } = this.props;
    return fetchArticlesOptions()
      .catch(() => this.openToastFailure('Articles options fetching failed'));
  };

  openToastFailure = message => toast.error(() => <Toast message={message} />);

  handleCreateContent = () => this.fetchContents();

  handleUpdateContent = () => this.fetchContents();

  handleDeleteContent = () => this.fetchContents();

  defaultBody = model => (model.body.length > 250 ? `${model.body.substring(0, 249)}...` : model.body);

  render() {
    const {
      articlesOptions,
      createContent,
      data,
      deleteContent,
      pagination,
      paginationSettings,
      updateContent
    } = this.props;
    const options = { articlesOptions };
    return (
      <BaseTable
        columns={this.columns}
        createModel={createContent}
        data={data}
        deleteModel={deleteContent}
        fetchModels={this.fetchContents}
        fields={this.fields}
        modelName={this.modelName}
        options={options}
        pagination={pagination}
        paginationSettings={paginationSettings}
        updateModel={updateContent}
        onCreateModel={this.handleCreateContent}
        onDeleteModel={this.handleDeleteContent}
        onUpdateModel={this.handleUpdateContent}
      />
    );
  }
}

Contents.propTypes = {
  articlesOptions: PropTypes.arrayOf(PropTypes.shape(schemas.article)).isRequired,
  createContent: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape(schemas.content)).isRequired,
  deleteContent: PropTypes.func.isRequired,
  fetchArticlesOptions: PropTypes.func.isRequired,
  fetchContents: PropTypes.func.isRequired,
  pagination: PropTypes.shape(schemas.pagination).isRequired,
  paginationSettings: PropTypes.shape(schemas.paginationSettings).isRequired,
  updateContent: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  articlesOptions: articlesReducers.getArticlesOptions(state),
  data: reducers.getContentsData(state),
  pagination: reducers.getContentsPagination(state),
  paginationSettings: reducers.getContentsPaginationSettings(state)
});

const mapDispatchToProps = dispatch => ({
  createContent: model => actions.createContent(model),
  deleteContent: id => actions.deleteContent(id),
  fetchArticlesOptions: () => dispatch(articlesActions.fetchArticlesOptions()),
  fetchContents: pagination => dispatch(actions.fetchContents(pagination)),
  updateContent: (id, model) => actions.updateContent(id, model)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Contents);
