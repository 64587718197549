import PropTypes from 'prop-types';
import qs from 'qs';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Container, Grid, Header, Image, Segment } from 'semantic-ui-react';
import LoginForm from '../components/login/LoginForm';
import Toast from '../components/Toast';
import { SERVER_URL } from '../constants/config';
import * as reducers from '../reducers/AuthReducers';
import * as schemas from '../schemas';

class Login extends React.Component {
  UNSAFE_componentWillMount() {
    const { onLogin } = this.props;
    if (window.location && window.location.search) {
      const { token, error } = qs.parse(window.location.search, { ignoreQueryPrefix: true });
      if (token) {
        localStorage.setItem('token', token);
        onLogin()
          .then(() => this.handleOauthLogin())
          .catch(() => this.handleOauthLoginFailure());
      } else if (error) {
        this.handleOauthLoginFailure();
      }
    }
  }

  openToastSuccess = () => toast.success(() => <Toast message="Login succeeded" />);

  openToastFailure = () => toast.error(() => <Toast message="Login failed" />);

  handleLogin = () => this.openToastSuccess();

  handleLoginFailure = () => this.openToastFailure();

  handleOauthLogin = () => this.openToastSuccess();

  handleOauthLoginFailure = () => {
    const { history } = this.props;
    history.replace('/login');
    this.handleLoginFailure();
  };

  render() {
    const { data } = this.props;
    return !data ? (
      <Container fluid id="wrapper">
        <Grid style={{ height: '100vh' }} textAlign="center" verticalAlign="middle">
          <Grid.Column style={{ maxWidth: '450px' }}>
            <Header style={{ fontSize: '20px' }} textAlign="center">
              <Image src="/logo512.png" />
              {' '}
              Login to admin panel of Articles App
            </Header>
            <Segment>
              <LoginForm onLogin={this.handleLogin} onLoginFailure={this.handleLoginFailure} />
              <Button.Group fluid style={{ marginTop: '15px' }}>
                <Button color="facebook" href={`${SERVER_URL}/auth/facebook`} icon="facebook" />
                <Button color="google plus" href={`${SERVER_URL}/auth/google`} icon="google plus" />
                <Button color="vk" href={`${SERVER_URL}/auth/vkontakte`} icon="vk" />
                <Button color="orange" href={`${SERVER_URL}/auth/odnoklassniki`} icon="odnoklassniki" />
              </Button.Group>
            </Segment>
          </Grid.Column>
        </Grid>
      </Container>
    ) : (
      <Redirect to="/" />
    );
  }
}

Login.propTypes = {
  data: PropTypes.shape(schemas.user),
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  onLogin: PropTypes.func
};

Login.defaultProps = {
  data: null,
  onLogin: () => Promise.resolve()
};

const mapStateToProps = state => ({
  data: reducers.getAuthData(state)
});

export default withRouter(connect(
  mapStateToProps,
  null
)(Login));
