export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const ME_SUCCESS = 'ME_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_OPTIONS_SUCCESS = 'FETCH_USERS_OPTIONS_SUCCESS';
export const FETCH_ARTICLES_SUCCESS = 'FETCH_ARTICLES_SUCCESS';
export const FETCH_ARTICLES_OPTIONS_SUCCESS = 'FETCH_ARTICLES_OPTIONS_SUCCESS';
export const FETCH_CONTENTS_SUCCESS = 'FETCH_CONTENTS_SUCCESS';
export const FETCH_TAGS_SUCCESS = 'FETCH_TAGS_SUCCESS';
export const FETCH_TAGS_OPTIONS_SUCCESS = 'FETCH_TAGS_OPTIONS_SUCCESS';
export const FETCH_ADMINS_SUCCESS = 'FETCH_ADMINS_SUCCESS';
