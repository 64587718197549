import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import * as actions from '../actions/AdminsActions';
import BaseTable from '../components/table/BaseTable';
import Toast from '../components/Toast';
import * as reducers from '../reducers/AdminsReducers';
import * as schemas from '../schemas';

class Admins extends React.Component {
  constructor(props) {
    super(props);
    this.columns = [
      { label: 'Name', name: 'name', sortable: true },
      { label: 'Username', name: 'username', sortable: true },
      { label: 'Email', name: 'email', sortable: true }
    ];
    this.fields = [
      {
        type: 'text',
        errorMessages: [
          'The name field is required.',
          'The name field may not be greater than 100 characters.'
        ],
        label: 'Name',
        name: 'name',
        validators: ['required', 'maxStringLength:100']
      },
      {
        type: 'text',
        errorMessages: [
          'The username field is required.',
          'The username field may not be greater than 100 characters.'
        ],
        label: 'Username',
        name: 'username',
        validators: ['required', 'maxStringLength:100']
      },
      {
        type: 'password',
        createErrorMessages: [
          'The password field is required.',
          'The password field may not be greater than 100 characters.'
        ],
        createValidators: ['required', 'maxStringLength:100'],
        default: () => '',
        label: 'Password',
        name: 'password',
        prepare: value => (value !== '' ? value : undefined),
        updateErrorMessages: [
          'The password field may not be greater than 100 characters.'
        ],
        updateValidators: ['maxStringLength:100']
      },
      {
        type: 'email',
        errorMessages: [
          'The email field is required.',
          'The email field must be a valid email.',
          'The email field may not be greater than 100 characters.'
        ],
        label: 'Email',
        name: 'email',
        validators: ['required', 'isEmail', 'maxStringLength:100']
      }
    ];
    this.modelName = 'admin';
  }

  UNSAFE_componentWillMount() {
    const { data } = this.props;
    if (!data.length) {
      this.fetchAdmins();
    }
  }

  fetchAdmins = ({ page, perPage, sort, sortDirection } = {}) => {
    const { fetchAdmins, paginationSettings } = this.props;
    return fetchAdmins({
      page: page || paginationSettings.page,
      perPage: perPage || paginationSettings.perPage,
      sort: sort || paginationSettings.sort,
      sortDirection: sortDirection || paginationSettings.sortDirection
    })
      .catch(() => this.openToastFailure('Admins fetching failed'));
  };

  openToastFailure = message => toast.error(() => <Toast message={message} />);

  handleCreateAdmin = () => this.fetchAdmins();

  handleUpdateAdmin = () => this.fetchAdmins();

  handleDeleteAdmin = () => this.fetchAdmins();

  render() {
    const { createAdmin, data, deleteAdmin, pagination, paginationSettings, updateAdmin } = this.props;
    return (
      <BaseTable
        columns={this.columns}
        createModel={createAdmin}
        data={data}
        deleteModel={deleteAdmin}
        fetchModels={this.fetchAdmins}
        fields={this.fields}
        modelName={this.modelName}
        pagination={pagination}
        paginationSettings={paginationSettings}
        updateModel={updateAdmin}
        onCreateModel={this.handleCreateAdmin}
        onDeleteModel={this.handleDeleteAdmin}
        onUpdateModel={this.handleUpdateAdmin}
      />
    );
  }
}

Admins.propTypes = {
  createAdmin: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape(schemas.admin)).isRequired,
  deleteAdmin: PropTypes.func.isRequired,
  fetchAdmins: PropTypes.func.isRequired,
  pagination: PropTypes.shape(schemas.pagination).isRequired,
  paginationSettings: PropTypes.shape(schemas.paginationSettings).isRequired,
  updateAdmin: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  data: reducers.getAdminsData(state),
  pagination: reducers.getAdminsPagination(state),
  paginationSettings: reducers.getAdminsPaginationSettings(state)
});

const mapDispatchToProps = dispatch => ({
  createAdmin: model => actions.createAdmin(model),
  deleteAdmin: id => actions.deleteAdmin(id),
  fetchAdmins: pagination => dispatch(actions.fetchAdmins(pagination)),
  updateAdmin: (id, model) => actions.updateAdmin(id, model)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Admins);
