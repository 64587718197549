import http from './index';

export const fetchArticlesAPI = paginationSettings => {
  const { page, perPage, sort, sortDirection } = paginationSettings;
  return http.get('articles', {
    params: {
      page,
      pageSize: perPage,
      sort: `${sort} ${sortDirection === 'ascending' ? 'ASC' : 'DESC'}`,
      includes: ['user', 'tags']
    }
  });
};

export const fetchArticlesOptionsAPI = () => http.get('articles', { params: { pageSize: 1000 } });

export const createArticleAPI = model => http.post('articles', model);

export const updateArticleAPI = (id, model) => http.put(`articles/${id}`, model);

export const deleteArticleAPI = id => http.delete(`articles/${id}`);

export const attachTagAPI = data => http.post('articles-tags', data);

export const detachTagAPI = data => http.delete('articles-tags', { data });
