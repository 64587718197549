import React from 'react';
import { Container, Header, Segment } from 'semantic-ui-react';

const NotFound = () => (
  <Container fluid id="wrapper">
    <Segment basic id="content-center">
      <Header size="huge">404 Page Not Found</Header>
    </Segment>
  </Container>
);

export default NotFound;
