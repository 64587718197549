import { fetchAdminsAPI, createAdminAPI, updateAdminAPI, deleteAdminAPI } from '../api/AdminsAPI';
import * as types from '../types';

export const fetchAdminsSuccess = (data = [], pagination = {}, paginationSettings = {}) => ({
  type: types.FETCH_ADMINS_SUCCESS,
  data,
  pagination,
  paginationSettings
});

export const fetchAdmins = paginationSettings => dispatch => fetchAdminsAPI(paginationSettings)
  .then(res => dispatch(fetchAdminsSuccess(res.data, res.pagination, paginationSettings)))
  .catch(error => Promise.reject(error));

export const createAdmin = model => createAdminAPI(model)
  .then(res => res.data)
  .catch(error => Promise.reject(error));

export const updateAdmin = (id, model) => updateAdminAPI(id, model)
  .then(res => res.data)
  .catch(error => Promise.reject(error));

export const deleteAdmin = id => deleteAdminAPI(id)
  .catch(error => Promise.reject(error));
