import {
  fetchArticlesAPI,
  fetchArticlesOptionsAPI,
  createArticleAPI,
  updateArticleAPI,
  deleteArticleAPI,
  attachTagAPI,
  detachTagAPI
} from '../api/ArticlesAPI';
import * as types from '../types';

export const fetchArticlesSuccess = (data = [], pagination = {}, paginationSettings = {}) => ({
  type: types.FETCH_ARTICLES_SUCCESS,
  data,
  pagination,
  paginationSettings
});

export const fetchArticlesOptionsSuccess = options => ({
  type: types.FETCH_ARTICLES_OPTIONS_SUCCESS,
  options
});

export const fetchArticles = paginationSettings => dispatch => fetchArticlesAPI(paginationSettings)
  .then(res => dispatch(fetchArticlesSuccess(res.data, res.pagination, paginationSettings)))
  .catch(error => Promise.reject(error));

export const fetchArticlesOptions = () => dispatch => fetchArticlesOptionsAPI()
  .then(res => dispatch(fetchArticlesOptionsSuccess(res.data)))
  .catch(error => Promise.reject(error));

export const createArticle = model => createArticleAPI(model)
  .then(res => res.data)
  .catch(error => Promise.reject(error));

export const updateArticle = (id, model) => updateArticleAPI(id, model)
  .then(res => res.data)
  .catch(error => Promise.reject(error));

export const deleteArticle = id => deleteArticleAPI(id)
  .catch(error => Promise.reject(error));

export const attachTag = data => attachTagAPI(data)
  .then(res => res.data)
  .catch(error => Promise.reject(error));

export const detachTag = data => detachTagAPI(data)
  .then(res => res.data)
  .catch(error => Promise.reject(error));
