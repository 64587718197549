import { fetchContentsAPI, createContentAPI, updateContentAPI, deleteContentAPI } from '../api/ContentsAPI';
import * as types from '../types';

export const fetchContentsSuccess = (data = [], pagination = {}, paginationSettings = {}) => ({
  type: types.FETCH_CONTENTS_SUCCESS,
  data,
  pagination,
  paginationSettings
});

export const fetchContents = paginationSettings => dispatch => fetchContentsAPI(paginationSettings)
  .then(res => dispatch(fetchContentsSuccess(res.data, res.pagination, paginationSettings)))
  .catch(error => Promise.reject(error));

export const createContent = model => createContentAPI(model)
  .then(res => res.data)
  .catch(error => Promise.reject(error));

export const updateContent = (id, model) => updateContentAPI(id, model)
  .then(res => res.data)
  .catch(error => Promise.reject(error));

export const deleteContent = id => deleteContentAPI(id)
  .catch(error => Promise.reject(error));
