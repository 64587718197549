import http from './index';

export const fetchTagsAPI = paginationSettings => {
  const { page, perPage, sort, sortDirection } = paginationSettings;
  return http.get('tags', {
    params: {
      page,
      pageSize: perPage,
      sort: `${sort} ${sortDirection === 'ascending' ? 'ASC' : 'DESC'}`,
      includes: ['articles']
    }
  });
};

export const fetchTagsOptionsAPI = () => http.get('tags', { params: { pageSize: 1000 } });

export const createTagAPI = model => http.post('tags', model);

export const updateTagAPI = (id, model) => http.put(`tags/${id}`, model);

export const deleteTagAPI = id => http.delete(`tags/${id}`);

export const attachArticleAPI = data => http.post('articles-tags', data);

export const detachArticleAPI = data => http.delete('articles-tags', { data });
