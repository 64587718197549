import http from './index';

export const fetchAdminsAPI = paginationSettings => {
  const { page, perPage, sort, sortDirection } = paginationSettings;
  return http.get('admins', {
    params: { page, pageSize: perPage, sort: `${sort} ${sortDirection === 'ascending' ? 'ASC' : 'DESC'}` }
  });
};

export const createAdminAPI = model => http.post('admins', model);

export const updateAdminAPI = (id, model) => http.put(`admins/${id}`, model);

export const deleteAdminAPI = id => http.delete(`admins/${id}`);
