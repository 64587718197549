import http from './index';

export const fetchUsersAPI = paginationSettings => {
  const { page, perPage, sort, sortDirection } = paginationSettings;
  return http.get('users', {
    params: { page, pageSize: perPage, sort: `${sort} ${sortDirection === 'ascending' ? 'ASC' : 'DESC'}` }
  });
};

export const fetchUsersOptionsAPI = () => http.get('users', { params: { pageSize: 1000 } });

export const createUserAPI = model => http.post('users', model);

export const updateUserAPI = (id, model) => http.put(`users/${id}`, model);

export const deleteUserAPI = id => http.delete(`users/${id}`);
