import {
  fetchTagsAPI,
  fetchTagsOptionsAPI,
  createTagAPI,
  updateTagAPI,
  deleteTagAPI,
  attachArticleAPI,
  detachArticleAPI
} from '../api/TagsAPI';
import * as types from '../types';

export const fetchTagsSuccess = (data = [], pagination = {}, paginationSettings = {}) => ({
  type: types.FETCH_TAGS_SUCCESS,
  data,
  pagination,
  paginationSettings
});

export const fetchTagsOptionsSuccess = options => ({
  type: types.FETCH_TAGS_OPTIONS_SUCCESS,
  options
});

export const fetchTags = paginationSettings => dispatch => fetchTagsAPI(paginationSettings)
  .then(res => dispatch(fetchTagsSuccess(res.data, res.pagination, paginationSettings)))
  .catch(error => Promise.reject(error));

export const fetchTagsOptions = () => dispatch => fetchTagsOptionsAPI()
  .then(res => dispatch(fetchTagsOptionsSuccess(res.data)))
  .catch(error => Promise.reject(error));

export const createTag = model => createTagAPI(model)
  .then(res => res.data)
  .catch(error => Promise.reject(error));

export const updateTag = (id, model) => updateTagAPI(id, model)
  .then(res => res.data)
  .catch(error => Promise.reject(error));

export const deleteTag = id => deleteTagAPI(id)
  .catch(error => Promise.reject(error));

export const attachArticle = data => attachArticleAPI(data)
  .then(res => res.data)
  .catch(error => Promise.reject(error));

export const detachArticle = data => detachArticleAPI(data)
  .then(res => res.data)
  .catch(error => Promise.reject(error));
