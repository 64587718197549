import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import * as articlesActions from '../actions/ArticlesActions';
import * as actions from '../actions/TagsActions';
import BaseTable from '../components/table/BaseTable';
import Toast from '../components/Toast';
import * as articlesReducers from '../reducers/ArticlesReducers';
import * as reducers from '../reducers/TagsReducers';
import * as schemas from '../schemas';

class Tags extends React.Component {
  constructor(props) {
    super(props);
    this.columns = [
      { label: 'Name', name: 'name', sortable: true },
      { label: 'Articles', name: 'articleIds', default: model => model.articles.map(a => a.title).join(', ') }
    ];
    this.fields = [
      {
        type: 'text',
        errorMessages: [
          'The name field is required.',
          'The name field may not be greater than 100 characters.'
        ],
        label: 'Name',
        name: 'name',
        validators: ['required', 'maxStringLength:100']
      },
      {
        type: 'dropdown',
        action: 'update',
        attachMethod: props.attachArticle,
        default: model => model.articles.map(a => a.id),
        detachMethod: props.detachArticle,
        errorMessages: [],
        label: 'Articles',
        multiple: true,
        name: 'articlesIds',
        options: options => options.articlesOptions.map(o => ({ text: o.title, value: o.id })),
        relationName: 'article',
        search: true,
        validators: []
      }
    ];
    this.modelName = 'tag';
  }

  UNSAFE_componentWillMount() {
    const { data } = this.props;
    if (!data.length) {
      this.fetchTags();
      this.fetchArticlesOptions();
    }
  }

  fetchTags = ({ page, perPage, sort, sortDirection } = {}) => {
    const { fetchTags, paginationSettings } = this.props;
    return fetchTags({
      page: page || paginationSettings.page,
      perPage: perPage || paginationSettings.perPage,
      sort: sort || paginationSettings.sort,
      sortDirection: sortDirection || paginationSettings.sortDirection
    })
      .catch(() => this.openToastFailure('Tags fetching failed'));
  };

  fetchTagsOptions = () => {
    const { fetchTagsOptions } = this.props;
    return fetchTagsOptions()
      .catch(() => this.openToastFailure('Tags options fetching failed'));
  };

  fetchArticles = () => {
    const { articlesPaginationSettings, fetchArticles } = this.props;
    return fetchArticles(articlesPaginationSettings)
      .catch(() => this.openToastFailure('Articles fetching failed'));
  };

  fetchArticlesOptions = () => {
    const { fetchArticlesOptions } = this.props;
    fetchArticlesOptions()
      .catch(() => this.openToastFailure('Articles options fetching failed'));
  };

  openToastFailure = message => toast.error(() => <Toast message={message} />);

  handleCreateTag = () => Promise.all([
    this.fetchTags(),
    this.fetchTagsOptions()
  ]);

  handleUpdateTag = () => Promise.all([
    this.fetchTags(),
    this.fetchTagsOptions(),
    this.fetchArticles()
  ]);

  handleDeleteTag = () => Promise.all([
    this.fetchTags(),
    this.fetchTagsOptions(),
    this.fetchArticles()
  ]);

  render() {
    const { articlesOptions, createTag, data, deleteTag, pagination, paginationSettings, updateTag } = this.props;
    const options = { articlesOptions };
    return (
      <BaseTable
        columns={this.columns}
        createModel={createTag}
        data={data}
        deleteModel={deleteTag}
        fetchModels={this.fetchTags}
        fields={this.fields}
        modelName={this.modelName}
        options={options}
        pagination={pagination}
        paginationSettings={paginationSettings}
        updateModel={updateTag}
        onCreateModel={this.handleCreateTag}
        onDeleteModel={this.handleDeleteTag}
        onUpdateModel={this.handleUpdateTag}
      />
    );
  }
}

Tags.propTypes = {
  articlesOptions: PropTypes.arrayOf(PropTypes.shape(schemas.article)).isRequired,
  articlesPaginationSettings: PropTypes.shape(schemas.paginationSettings).isRequired,
  attachArticle: PropTypes.func.isRequired,
  createTag: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape(schemas.tag)).isRequired,
  deleteTag: PropTypes.func.isRequired,
  detachArticle: PropTypes.func.isRequired,
  fetchArticles: PropTypes.func.isRequired,
  fetchArticlesOptions: PropTypes.func.isRequired,
  fetchTags: PropTypes.func.isRequired,
  fetchTagsOptions: PropTypes.func.isRequired,
  pagination: PropTypes.shape(schemas.pagination).isRequired,
  paginationSettings: PropTypes.shape(schemas.paginationSettings).isRequired,
  updateTag: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  articlesOptions: articlesReducers.getArticlesOptions(state),
  articlesPaginationSettings: articlesReducers.getArticlesPaginationSettings(state),
  data: reducers.getTagsData(state),
  pagination: reducers.getTagsPagination(state),
  paginationSettings: reducers.getTagsPaginationSettings(state)
});

const mapDispatchToProps = dispatch => ({
  attachArticle: data => actions.attachArticle(data),
  createTag: model => actions.createTag(model),
  deleteTag: id => actions.deleteTag(id),
  detachArticle: data => actions.detachArticle(data),
  fetchArticles: pagination => dispatch(articlesActions.fetchArticles(pagination)),
  fetchArticlesOptions: () => dispatch(articlesActions.fetchArticlesOptions()),
  fetchTags: pagination => dispatch(actions.fetchTags(pagination)),
  fetchTagsOptions: () => dispatch(actions.fetchTagsOptions()),
  updateTag: (id, model) => actions.updateTag(id, model)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Tags);
