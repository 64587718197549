import http from './index';

export const fetchContentsAPI = paginationSettings => {
  const { page, perPage, sort, sortDirection } = paginationSettings;
  return http.get('contents', {
    params: {
      page,
      pageSize: perPage,
      sort: `${sort} ${sortDirection === 'ascending' ? 'ASC' : 'DESC'}`,
      includes: ['article']
    }
  });
};

export const createContentAPI = model => http.post('contents', model);

export const updateContentAPI = (id, model) => http.put(`contents/${id}`, model);

export const deleteContentAPI = id => http.delete(`contents/${id}`);
