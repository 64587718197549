import PropTypes from 'prop-types';
import React from 'react';
import { Icon, Menu, Table } from 'semantic-ui-react';
import * as schemas from '../../schemas';
import CreateModal from '../modal/CreateModal';
import DeleteModal from '../modal/DeleteModal';
import UpdateModal from '../modal/UpdateModal';

class BaseTable extends React.Component {
  handleSortChange = currentSort => () => {
    const { fetchModels, paginationSettings: { perPage, sort, sortDirection } } = this.props;

    if (sort !== currentSort) {
      const currentSortDirection = 'ascending';
      fetchModels({
        page: 1,
        perPage,
        sort: currentSort,
        sortDirection: currentSortDirection
      });
      return;
    }

    const currentSortDirection = sortDirection === 'ascending' ? 'descending' : 'ascending';
    fetchModels({
      page: 1,
      perPage,
      sort,
      sortDirection: currentSortDirection
    });
  };

  handlePageChange = currentPage => () => {
    const { fetchModels, paginationSettings: { page, perPage, sort, sortDirection } } = this.props;
    if (page !== currentPage) {
      fetchModels({
        page: currentPage,
        perPage,
        sort,
        sortDirection
      });
    }
  };

  getSortDirection = currentSort => {
    const { paginationSettings: { sort, sortDirection } } = this.props;
    return sort === currentSort ? sortDirection : null;
  };

  render() {
    const {
      columns,
      createModel,
      data,
      deleteModel,
      fields,
      modelName,
      onCreateModel,
      onDeleteModel,
      onUpdateModel,
      options,
      pagination,
      updateModel
    } = this.props;
    const { page, pageCount } = pagination;

    const pageStart = page - 2 >= 1 ? page - 2 : 1;
    const pageEnd = page + 2 <= pageCount ? page + 2 : pageCount;

    const prevPage = page - 1 >= 1 ? page - 1 : 1;
    const nextPage = page + 1 <= pageCount ? page + 1 : pageCount;

    const pages = [];
    for (let i = pageStart; i <= pageEnd; i++) {
      pages.push(
        <Menu.Item key={i} active={page === i} onClick={this.handlePageChange(i)}>
          {i}
        </Menu.Item>
      );
    }

    return (
      <Table celled compact sortable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              sorted={this.getSortDirection('id')}
              style={{ width: '75px' }}
              onClick={this.handleSortChange('id')}
            >
              #
            </Table.HeaderCell>
            {columns.map(column => {
              if (column.sortable) {
                return (
                  <Table.HeaderCell
                    key={column.name}
                    sorted={this.getSortDirection(column.name)}
                    onClick={this.handleSortChange(column.name)}
                  >
                    {column.label}
                  </Table.HeaderCell>
                );
              }
              return (
                <Table.HeaderCell key={column.name}>
                  {column.label}
                </Table.HeaderCell>
              );
            })}
            <Table.HeaderCell style={{ width: '105px' }} textAlign="center">
              Actions
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {data.length > 0 ? data.map(model => (
            <Table.Row key={model.id}>
              <Table.Cell>
                {model.id}
              </Table.Cell>
              {columns.map(column => {
                const value = model[column.name];
                return (
                  <Table.Cell key={column.name}>
                    {column.default ? column.default(model) : value}
                  </Table.Cell>
                );
              })}
              <Table.Cell>
                <UpdateModal
                  fields={fields}
                  model={model}
                  modelName={modelName}
                  options={options}
                  updateModel={updateModel}
                  onUpdateModel={onUpdateModel}
                />
                <DeleteModal
                  deleteModel={deleteModel}
                  model={model}
                  modelName={modelName}
                  onDeleteModel={onDeleteModel}
                />
              </Table.Cell>
            </Table.Row>
          )) : (
            <Table.Row style={{ height: '50px' }}>
              <Table.Cell colSpan={columns.length + 2} textAlign="center">
                Data not found
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>

        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan={columns.length + 2}>
              <CreateModal
                createModel={createModel}
                fields={fields}
                modelName={modelName}
                options={options}
                onCreateModel={onCreateModel}
              />

              <Menu pagination floated="right">
                <Menu.Item icon onClick={this.handlePageChange(prevPage)}>
                  <Icon name="chevron left" />
                </Menu.Item>
                {pages}
                <Menu.Item icon onClick={this.handlePageChange(nextPage)}>
                  <Icon name="chevron right" />
                </Menu.Item>
              </Menu>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    );
  }
}

BaseTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape(schemas.column)).isRequired,
  createModel: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  deleteModel: PropTypes.func.isRequired,
  fetchModels: PropTypes.func.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape(schemas.field)).isRequired,
  modelName: PropTypes.string.isRequired,
  onCreateModel: PropTypes.func,
  onDeleteModel: PropTypes.func,
  onUpdateModel: PropTypes.func,
  options: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any))),
  pagination: PropTypes.shape(schemas.pagination).isRequired,
  paginationSettings: PropTypes.shape(schemas.paginationSettings).isRequired,
  updateModel: PropTypes.func.isRequired
};

BaseTable.defaultProps = {
  onCreateModel: () => Promise.resolve(),
  onDeleteModel: () => Promise.resolve(),
  onUpdateModel: () => Promise.resolve(),
  options: null
};

export default BaseTable;
