import { fetchUsersAPI, fetchUsersOptionsAPI, createUserAPI, updateUserAPI, deleteUserAPI } from '../api/UsersAPI';
import * as types from '../types';

export const fetchUsersSuccess = (data = [], pagination = {}, paginationSettings = {}) => ({
  type: types.FETCH_USERS_SUCCESS,
  data,
  pagination,
  paginationSettings
});

export const fetchUsersOptionsSuccess = options => ({
  type: types.FETCH_USERS_OPTIONS_SUCCESS,
  options
});

export const fetchUsers = paginationSettings => dispatch => fetchUsersAPI(paginationSettings)
  .then(res => dispatch(fetchUsersSuccess(res.data, res.pagination, paginationSettings)))
  .catch(error => Promise.reject(error));

export const fetchUsersOptions = () => dispatch => fetchUsersOptionsAPI()
  .then(res => dispatch(fetchUsersOptionsSuccess(res.data)))
  .catch(error => Promise.reject(error));

export const createUser = model => createUserAPI(model)
  .then(res => res.data)
  .catch(error => Promise.reject(error));

export const updateUser = (id, model) => updateUserAPI(id, model)
  .then(res => res.data)
  .catch(error => Promise.reject(error));

export const deleteUser = id => deleteUserAPI(id)
  .catch(error => Promise.reject(error));
