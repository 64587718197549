import axios from 'axios';

import { SERVER_URL } from '../constants/config';

const http = axios.create({
  baseURL: SERVER_URL
});

http.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = token; // eslint-disable-line no-param-reassign
    }
    return config;
  },
  error => Promise.reject(error)
);

http.interceptors.response.use(
  response => {
    if (response.headers.token) {
      localStorage.setItem('token', response.headers.token);
    }
    return response.data;
  },
  error => Promise.reject(error.response.data)
);

export default http;
