import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import * as actions from '../actions/ArticlesActions';
import * as contentsActions from '../actions/ContentsActions';
import * as tagsActions from '../actions/TagsActions';
import * as usersActions from '../actions/UsersActions';
import BaseTable from '../components/table/BaseTable';
import Toast from '../components/Toast';
import * as reducers from '../reducers/ArticlesReducers';
import * as contentsReducers from '../reducers/ContentsReducers';
import * as tagsReducers from '../reducers/TagsReducers';
import * as usersReducers from '../reducers/UsersReducers';
import * as schemas from '../schemas';

class Articles extends React.Component {
  constructor(props) {
    super(props);
    this.columns = [
      { label: 'User', name: 'userId', default: model => model.user.name },
      { label: 'Title', name: 'title', sortable: true },
      { label: 'Description', name: 'description', default: this.defaultDescription },
      { label: 'Status', name: 'status', sortable: true },
      { label: 'Tags', name: 'tagsIds', default: model => model.tags.map(t => t.name).join(', ') }
    ];
    this.fields = [
      {
        type: 'dropdown',
        errorMessages: [
          'The user field is required.'
        ],
        label: 'User',
        name: 'userId',
        options: options => options.usersOptions.map(o => ({ text: o.name, value: o.id })),
        search: true,
        validators: ['required']
      },
      {
        type: 'text',
        errorMessages: [
          'The title field is required.',
          'The title field may not be greater than 100 characters.'
        ],
        label: 'Title',
        name: 'title',
        validators: ['required', 'maxStringLength:100']
      },
      {
        type: 'textarea',
        errorMessages: [
          'The description field is required.',
          'The description field may not be greater than 500 characters.'
        ],
        label: 'Description',
        name: 'description',
        validators: ['required', 'maxStringLength:500']
      },
      {
        type: 'dropdown',
        errorMessages: [
          'The status field is required.'
        ],
        label: 'Status',
        name: 'status',
        options: [
          { text: 'Published', value: 'published' },
          { text: 'Draft', value: 'draft' }
        ],
        validators: ['required']
      },
      {
        type: 'dropdown',
        action: 'update',
        attachMethod: props.attachTag,
        default: model => model.tags.map(t => t.id),
        detachMethod: props.detachTag,
        errorMessages: [],
        label: 'Tags',
        multiple: true,
        name: 'tagsIds',
        options: options => options.tagsOptions.map(o => ({ text: o.name, value: o.id })),
        relationName: 'tag',
        search: true,
        validators: []
      }
    ];
    this.modelName = 'article';
  }

  UNSAFE_componentWillMount() {
    const { data } = this.props;
    if (!data.length) {
      this.fetchArticles();
      this.fetchTagsOptions();
      this.fetchUsersOptions();
    }
  }

  fetchArticles = ({ page, perPage, sort, sortDirection } = {}) => {
    const { fetchArticles, paginationSettings } = this.props;
    return fetchArticles({
      page: page || paginationSettings.page,
      perPage: perPage || paginationSettings.perPage,
      sort: sort || paginationSettings.sort,
      sortDirection: sortDirection || paginationSettings.sortDirection
    })
      .catch(() => this.openToastFailure('Articles fetching failed'));
  };

  fetchArticlesOptions = () => {
    const { fetchArticlesOptions } = this.props;
    return fetchArticlesOptions()
      .catch(() => this.openToastFailure('Articles options fetching failed'));
  };

  fetchContents = () => {
    const { contentsPaginationSettings, fetchContents } = this.props;
    return fetchContents(contentsPaginationSettings)
      .catch(() => this.openToastFailure('Contents fetching failed'));
  };

  fetchTags = () => {
    const { fetchTags, tagsPaginationSettings } = this.props;
    return fetchTags(tagsPaginationSettings)
      .catch(() => this.openToastFailure('Tags fetching failed'));
  };

  fetchTagsOptions = () => {
    const { fetchTagsOptions } = this.props;
    return fetchTagsOptions()
      .catch(() => this.openToastFailure('Tags options fetching failed'));
  };

  fetchUsersOptions = () => {
    const { fetchUsersOptions } = this.props;
    return fetchUsersOptions()
      .catch(() => this.openToastFailure('Users options fetching failed'));
  };

  openToastFailure = message => toast.error(() => <Toast message={message} />);

  handleCreateArticle = () => Promise.all([
    this.fetchArticles(),
    this.fetchArticlesOptions()
  ]);

  handleUpdateArticle = () => Promise.all([
    this.fetchArticles(),
    this.fetchArticlesOptions(),
    this.fetchContents(),
    this.fetchTags()
  ]);

  handleDeleteArticle = () => Promise.all([
    this.fetchArticles(),
    this.fetchArticlesOptions(),
    this.fetchContents(),
    this.fetchTags()
  ]);

  defaultDescription = model => (model.description.length > 250 ? `${model.description.substring(0, 249)}...` : model.description);

  render() {
    const {
      createArticle,
      data,
      deleteArticle,
      pagination,
      paginationSettings,
      tagsOptions,
      updateArticle,
      usersOptions
    } = this.props;
    const options = { tagsOptions, usersOptions };
    return (
      <BaseTable
        columns={this.columns}
        createModel={createArticle}
        data={data}
        deleteModel={deleteArticle}
        fetchModels={this.fetchArticles}
        fields={this.fields}
        modelName={this.modelName}
        options={options}
        pagination={pagination}
        paginationSettings={paginationSettings}
        updateModel={updateArticle}
        onCreateModel={this.handleCreateArticle}
        onDeleteModel={this.handleDeleteArticle}
        onUpdateModel={this.handleUpdateArticle}
      />
    );
  }
}

Articles.propTypes = {
  attachTag: PropTypes.func.isRequired,
  contentsPaginationSettings: PropTypes.shape(schemas.paginationSettings).isRequired,
  createArticle: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape(schemas.article)).isRequired,
  deleteArticle: PropTypes.func.isRequired,
  detachTag: PropTypes.func.isRequired,
  fetchArticles: PropTypes.func.isRequired,
  fetchArticlesOptions: PropTypes.func.isRequired,
  fetchContents: PropTypes.func.isRequired,
  fetchTags: PropTypes.func.isRequired,
  fetchTagsOptions: PropTypes.func.isRequired,
  fetchUsersOptions: PropTypes.func.isRequired,
  pagination: PropTypes.shape(schemas.pagination).isRequired,
  paginationSettings: PropTypes.shape(schemas.paginationSettings).isRequired,
  tagsOptions: PropTypes.arrayOf(PropTypes.shape(schemas.tag)).isRequired,
  tagsPaginationSettings: PropTypes.shape(schemas.paginationSettings).isRequired,
  updateArticle: PropTypes.func.isRequired,
  usersOptions: PropTypes.arrayOf(PropTypes.shape(schemas.user)).isRequired
};

const mapStateToProps = state => ({
  contentsPaginationSettings: contentsReducers.getContentsPaginationSettings(state),
  data: reducers.getArticlesData(state),
  pagination: reducers.getArticlesPagination(state),
  paginationSettings: reducers.getArticlesPaginationSettings(state),
  tagsOptions: tagsReducers.getTagsOptions(state),
  tagsPaginationSettings: tagsReducers.getTagsPaginationSettings(state),
  usersOptions: usersReducers.getUsersOptions(state)
});

const mapDispatchToProps = dispatch => ({
  attachTag: data => actions.attachTag(data),
  createArticle: model => actions.createArticle(model),
  deleteArticle: id => actions.deleteArticle(id),
  detachTag: data => actions.detachTag(data),
  fetchArticles: pagination => dispatch(actions.fetchArticles(pagination)),
  fetchArticlesOptions: () => dispatch(actions.fetchArticlesOptions()),
  fetchContents: pagination => dispatch(contentsActions.fetchContents(pagination)),
  fetchTags: pagination => dispatch(tagsActions.fetchTags(pagination)),
  fetchTagsOptions: () => dispatch(tagsActions.fetchTagsOptions()),
  fetchUsersOptions: () => dispatch(usersActions.fetchUsersOptions()),
  updateArticle: (id, model) => actions.updateArticle(id, model)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Articles);
