import * as types from '../types';

const initialState = {
  data: [],
  pagination: {
    rowCount: 0
  },
  paginationSettings: {
    page: 1,
    perPage: 10,
    sort: 'id',
    sortDirection: 'ascending'
  }
};

export const ContentsReducers = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.FETCH_CONTENTS_SUCCESS:
      return {
        ...state,
        data: action.data,
        pagination: action.pagination,
        paginationSettings: action.paginationSettings
      };

    default:
      return state;
  }
};

export const getContentsData = state => state.contents.data;
export const getContentsPagination = state => state.contents.pagination;
export const getContentsPaginationSettings = state => state.contents.paginationSettings;
