import { loginAuthAPI, meAuthAPI } from '../api/AuthAPI';
import * as types from '../types';

export const loginSuccess = (data = {}) => ({
  type: types.LOGIN_SUCCESS,
  data
});

export const meSuccess = (data = {}) => ({
  type: types.ME_SUCCESS,
  data
});

export const logout = () => ({
  type: types.LOGOUT
});

export const login = data => dispatch => loginAuthAPI(data)
  .then(res => dispatch(loginSuccess(res.data)))
  .catch(error => Promise.reject(error));

export const me = () => dispatch => meAuthAPI()
  .then(res => dispatch(meSuccess(res.data)))
  .catch(error => Promise.reject(error));
