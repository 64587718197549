import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'semantic-ui-react';
import { Form, Input } from 'semantic-ui-react-form-validator';
import * as actions from '../../actions/AuthActions';

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: ''
    };
  }

  handleSubmit = () => {
    const { login, onLogin, onLoginFailure } = this.props;
    const { username, password } = this.state;
    login({
      username,
      password
    })
      .then(() => onLogin())
      .catch(() => onLoginFailure());
  };

  handleInputChange = (event, { name, value }) => {
    this.setState({ [name]: value });
  };

  render() {
    const { username, password } = this.state;
    return (
      <Form id="login-form" onSubmit={this.handleSubmit}>
        <Input
          errorMessages={[
            'The username field is required.',
            'The username field may not be greater than 100 characters.'
          ]}
          icon="user"
          iconPosition="left"
          name="username"
          placeholder="Username"
          type="text"
          validators={['required', 'maxStringLength:100']}
          value={username}
          width={16}
          onChange={this.handleInputChange}
        />
        <Input
          errorMessages={[
            'The password field is required.',
            'The password field may not be greater than 100 characters.'
          ]}
          icon="lock"
          iconPosition="left"
          name="password"
          placeholder="Password"
          type="password"
          validators={['required', 'maxStringLength:100']}
          value={password}
          width={16}
          onChange={this.handleInputChange}
        />
        <Button fluid form="login-form" type="submit">Login</Button>
      </Form>
    );
  }
}

LoginForm.propTypes = {
  login: PropTypes.func.isRequired,
  onLogin: PropTypes.func,
  onLoginFailure: PropTypes.func
};

LoginForm.defaultProps = {
  onLogin: () => Promise.resolve(),
  onLoginFailure: () => Promise.resolve()
};

const mapDispatchToProps = dispatch => ({
  login: model => dispatch(actions.login(model))
});

export default connect(
  null,
  mapDispatchToProps
)(LoginForm);
