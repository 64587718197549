import * as types from '../types';

const initialState = {
  data: null
};

export const AuthReducers = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.LOGIN_SUCCESS:
    case types.ME_SUCCESS:
      return {
        ...state,
        data: action.data
      };

    case types.LOGOUT:
      return {
        ...state,
        data: null
      };

    default:
      return state;
  }
};

export const getAuthData = state => state.auth.data;
