import PropTypes from 'prop-types';
import React from 'react';

const Toast = ({ message }) => <div style={{ padding: '0 0 0 20px' }}>{message}</div>;

Toast.propTypes = {
  message: PropTypes.string.isRequired
};

export default Toast;
