import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import * as actions from './actions/AuthActions';
import Basic from './pages/Basic';
import Loading from './pages/Loading';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import './App.css';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: false };
  }

  UNSAFE_componentWillMount() {
    this.setState({ loading: true });
    this.fetchMe()
      .catch(() => {})
      .finally(() => this.setState({ loading: false }));
  }

  fetchMe = () => {
    const { me } = this.props;
    return me();
  };

  handleLogin = () => this.fetchMe();

  render() {
    const { loading } = this.state;

    if (loading) {
      return <Loading />;
    }

    return (
      <Switch>
        <Route exact path="/login" render={() => <Login onLogin={this.handleLogin} />} />
        <Route exact component={Basic} path={['/', '/users', '/articles', '/contents', '/tags', '/admins']} />
        <Route component={NotFound} path="*" />
      </Switch>
    );
  }
}

App.propTypes = {
  me: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
  me: () => dispatch(actions.me())
});

export default connect(
  null,
  mapDispatchToProps
)(App);
