import { isFunction } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Dropdown, Form, Input, TextArea } from 'semantic-ui-react-form-validator';
import * as schemas from '../../schemas';

class UpdateForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getDefaultState(props);
  }

  getDefaultState = props => props.fields.reduce((state, field) => {
    if (field.action === 'create') {
      return state;
    }
    const value = field.default ? field.default(props.model) : props.model[field.name];
    return Object.assign(state, { [field.name]: value });
  }, {});

  handleSubmit = () => {
    const { fields, model, onUpdateModel, onUpdateModelFailure, updateModel } = this.props;
    const state = this.prepareState(fields, model);
    updateModel(model.id, state)
      .then(() => onUpdateModel())
      .catch(() => onUpdateModelFailure());
  };

  prepareState = fields => fields.reduce((state, field) => {
    if (field.attachMethod || field.detachMethod) {
      return state;
    }
    const { [field.name]: value } = this.state;
    return Object.assign(state, { [field.name]: field.prepare ? field.prepare(value) : value });
  }, {});

  handleInputChange = (event, { name, value }) => {
    this.setState({ [name]: value });
  };

  handleRelationChange = relationName => (event, { name, value }) => {
    const { fields, model, modelName, onChangeRelation, onChangeRelationFailure } = this.props;
    const { [name]: oldValue } = this.state;
    const isAttach = value.length > oldValue.length;
    const { attachMethod, detachMethod } = fields.find(field => field.name === name);
    const relationId = isAttach
      ? value.filter(i => !oldValue.includes(i))[0]
      : oldValue.filter(i => !value.includes(i))[0];
    (isAttach ? attachMethod : detachMethod)({
      [`${modelName}Id`]: model.id,
      [`${relationName}Id`]: relationId
    })
      .then(() => this.setState({ [name]: value }))
      .then(() => onChangeRelation(relationName, isAttach))
      .catch(() => onChangeRelationFailure(relationName, isAttach));
  };

  getFieldValue = name => {
    const { [name]: value } = this.state;
    return value;
  };

  render() {
    const { fields, id, options } = this.props;
    return (
      <Form id={id} onSubmit={this.handleSubmit}>
        {fields.map(field => {
          if (field.action === 'create') return null;
          if (['email', 'password', 'text'].includes(field.type)) {
            return (
              <Input
                key={field.name}
                errorMessages={field.updateErrorMessages || field.errorMessages}
                label={field.label}
                name={field.name}
                placeholder={field.label}
                type={field.type}
                validators={field.updateValidators || field.validators}
                value={this.getFieldValue(field.name)}
                width={16}
                onChange={this.handleInputChange}
              />
            );
          }
          if (field.type === 'textarea') {
            return (
              <TextArea
                key={field.name}
                errorMessages={field.updateErrorMessages || field.errorMessages}
                label={field.label}
                name={field.name}
                placeholder={field.label}
                validators={field.updateValidators || field.validators}
                value={this.getFieldValue(field.name)}
                width={16}
                onChange={this.handleInputChange}
              />
            );
          }
          if (field.type === 'dropdown') {
            const handleFieldChange = (field.attachMethod && field.detachMethod)
              ? this.handleRelationChange(field.relationName)
              : this.handleInputChange;
            return (
              <Dropdown
                key={field.name}
                selection
                errorMessages={field.updateErrorMessages || field.errorMessages}
                label={field.label}
                multiple={field.multiple}
                name={field.name}
                options={isFunction(field.options) ? field.options(options) : field.options}
                placeholder={field.label}
                search={field.search}
                validators={field.updateValidators || field.validators}
                value={this.getFieldValue(field.name)}
                width={16}
                onChange={handleFieldChange}
              />
            );
          }
          return null;
        })}
      </Form>
    );
  }
}

UpdateForm.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape(schemas.field)).isRequired,
  id: PropTypes.string.isRequired,
  model: PropTypes.objectOf(PropTypes.any).isRequired,
  modelName: PropTypes.string.isRequired,
  onChangeRelation: PropTypes.func,
  onChangeRelationFailure: PropTypes.func,
  onUpdateModel: PropTypes.func,
  onUpdateModelFailure: PropTypes.func,
  options: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any))),
  updateModel: PropTypes.func.isRequired
};

UpdateForm.defaultProps = {
  onChangeRelation: () => Promise.resolve(),
  onChangeRelationFailure: () => Promise.resolve(),
  onUpdateModel: () => Promise.resolve(),
  onUpdateModelFailure: () => Promise.resolve(),
  options: null
};

export default UpdateForm;
