import { upperFirst } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { toast } from 'react-toastify';
import { Button, Icon, Modal } from 'semantic-ui-react';
import * as schemas from '../../schemas';
import UpdateForm from '../form/UpdateForm';
import Toast from '../Toast';

class UpdateModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }

  switchOpen = () => this.setState(state => ({ open: !state.open }));

  openToastSuccess = message => toast.success(() => <Toast message={message} />);

  openToastFailure = message => toast.error(() => <Toast message={message} />);

  handleChangeRelation = (relationName, isAttach = true) => {
    const { onUpdateModel } = this.props;
    onUpdateModel()
      .then(() => this.openToastSuccess(`${upperFirst(relationName)} ${isAttach ? 'at' : 'de'}taching succeeded`));
  };

  handleChangeRelationFailure = (relationName, isAttach = true) => {
    const { onUpdateModelFailure } = this.props;
    onUpdateModelFailure()
      .then(() => this.openToastFailure(`${upperFirst(relationName)} ${isAttach ? 'at' : 'de'}taching failed`));
  };

  handleUpdateModel = () => {
    const { modelName, onUpdateModel } = this.props;
    onUpdateModel()
      .then(() => this.switchOpen())
      .then(() => this.openToastSuccess(`${upperFirst(modelName)} updating succeeded`));
  };

  handleUpdateModelFailure = () => {
    const { modelName, onUpdateModelFailure } = this.props;
    onUpdateModelFailure()
      .then(() => this.openToastFailure(`${upperFirst(modelName)} updating failed`));
  };

  render() {
    const { fields, model, modelName, options, updateModel } = this.props;
    const { open } = this.state;
    const trigger = (
      <Button basic icon onClick={this.switchOpen}>
        <Icon name="pencil" />
      </Button>
    );
    return (
      <Modal open={open} trigger={trigger} onClose={this.switchOpen}>
        <Modal.Header>
          {upperFirst(modelName)}
          {' '}
          updating
        </Modal.Header>
        <Modal.Content>
          <UpdateForm
            fields={fields}
            id={`update-form-${model.id}`}
            model={model}
            modelName={modelName}
            options={options}
            updateModel={updateModel}
            onChangeRelation={this.handleChangeRelation}
            onChangeRelationFailure={this.handleChangeRelationFailure}
            onUpdateModel={this.handleUpdateModel}
            onUpdateModelFailure={this.handleUpdateModelFailure}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button color="red" style={{ width: '85px' }} onClick={this.switchOpen}>Cancel</Button>
          <Button color="green" form={`update-form-${model.id}`} style={{ width: '85px' }} type="submit">
            OK
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

UpdateModal.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape(schemas.field)).isRequired,
  model: PropTypes.objectOf(PropTypes.any).isRequired,
  modelName: PropTypes.string.isRequired,
  onUpdateModel: PropTypes.func,
  onUpdateModelFailure: PropTypes.func,
  options: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any))),
  updateModel: PropTypes.func.isRequired
};

UpdateModal.defaultProps = {
  onUpdateModel: () => Promise.resolve(),
  onUpdateModelFailure: () => Promise.resolve(),
  options: null
};

export default UpdateModal;
