import { upperFirst } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { toast } from 'react-toastify';
import { Button, Icon, Modal } from 'semantic-ui-react';
import * as schemas from '../../schemas';
import CreateForm from '../form/CreateForm';
import Toast from '../Toast';

class CreateModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }

  switchOpen = () => this.setState(state => ({ open: !state.open }));

  openToastSuccess = message => toast.success(() => <Toast message={message} />);

  openToastFailure = message => toast.error(() => <Toast message={message} />);

  handleCreateModel = () => {
    const { modelName, onCreateModel } = this.props;
    onCreateModel()
      .then(() => this.switchOpen())
      .then(() => this.openToastSuccess(`${upperFirst(modelName)} creating succeeded`));
  };

  handleCreateModelFailure = () => {
    const { modelName, onCreateModelFailure } = this.props;
    onCreateModelFailure()
      .then(() => this.openToastFailure(`${upperFirst(modelName)} creating failed`));
  };

  render() {
    const { createModel, fields, modelName, options } = this.props;
    const { open } = this.state;
    const trigger = (
      <Button basic icon onClick={this.switchOpen}>
        <Icon name="plus" />
      </Button>
    );
    return (
      <Modal open={open} trigger={trigger} onClose={this.switchOpen}>
        <Modal.Header>
          {upperFirst(modelName)}
          {' '}
          creating
        </Modal.Header>
        <Modal.Content>
          <CreateForm
            createModel={createModel}
            fields={fields}
            id="create-form"
            options={options}
            onCreateModel={this.handleCreateModel}
            onCreateModelFailure={this.handleCreateModelFailure}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button color="red" style={{ width: '85px' }} onClick={this.switchOpen}>Cancel</Button>
          <Button color="green" form="create-form" style={{ width: '85px' }} type="submit">
            OK
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

CreateModal.propTypes = {
  createModel: PropTypes.func.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape(schemas.field)).isRequired,
  modelName: PropTypes.string.isRequired,
  onCreateModel: PropTypes.func,
  onCreateModelFailure: PropTypes.func,
  options: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)))
};

CreateModal.defaultProps = {
  onCreateModel: () => Promise.resolve(),
  onCreateModelFailure: () => Promise.resolve(),
  options: null
};

export default CreateModal;
