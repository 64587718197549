import PropTypes from 'prop-types';

export const user = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired
};

export const article = {
  id: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  status: PropTypes.oneOf(['published', 'draft']).isRequired,
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired
};

export const content = {
  id: PropTypes.number.isRequired,
  articleId: PropTypes.number.isRequired,
  body: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired
};

export const tag = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired
};

export const admin = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired
};

export const pagination = {
  page: PropTypes.number,
  pageSize: PropTypes.number,
  rowCount: PropTypes.number.isRequired,
  pageCount: PropTypes.number
};

export const paginationSettings = {
  page: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  sort: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired
};

export const column = {
  default: PropTypes.func,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  sortable: PropTypes.bool
};

export const option = {
  text: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired
};

export const field = {
  type: PropTypes.oneOf(['email', 'password', 'text', 'textarea', 'dropdown']).isRequired,
  action: PropTypes.oneOf(['create', 'update']),
  attachMethod: PropTypes.func,
  createErrorMessages: PropTypes.arrayOf(PropTypes.string),
  createValidators: PropTypes.arrayOf(PropTypes.string),
  default: PropTypes.func,
  detachMethod: PropTypes.func,
  errorMessages: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  name: PropTypes.string.isRequired,
  options: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape(option)), PropTypes.func]),
  prepare: PropTypes.func,
  relationName: PropTypes.string,
  search: PropTypes.bool,
  updateErrorMessages: PropTypes.arrayOf(PropTypes.string),
  updateValidators: PropTypes.arrayOf(PropTypes.string),
  validators: PropTypes.arrayOf(PropTypes.string)
};
