import { isFunction } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Dropdown, Form, Input, TextArea } from 'semantic-ui-react-form-validator';
import * as schemas from '../../schemas';

class CreateForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getDefaultState(props);
  }

  getDefaultState = props => props.fields.reduce((state, field) => {
    if (field.action === 'update') {
      return state;
    }
    return Object.assign(state, { [field.name]: '' });
  }, {});

  handleSubmit = () => {
    const { createModel, onCreateModel, onCreateModelFailure } = this.props;
    createModel(this.state)
      .then(() => onCreateModel())
      .catch(() => onCreateModelFailure());
  };

  handleInputChange = (event, { name, value }) => {
    this.setState({ [name]: value });
  };

  getFieldValue = name => {
    const { [name]: value } = this.state;
    return value;
  };

  render() {
    const { fields, id, options } = this.props;
    return (
      <Form id={id} onSubmit={this.handleSubmit}>
        {fields.map(field => {
          if (field.action === 'update') return null;
          if (['email', 'password', 'text'].includes(field.type)) {
            return (
              <Input
                key={field.name}
                errorMessages={field.createErrorMessages || field.errorMessages}
                label={field.label}
                name={field.name}
                placeholder={field.label}
                type={field.type}
                validators={field.createValidators || field.validators}
                value={this.getFieldValue(field.name)}
                width={16}
                onChange={this.handleInputChange}
              />
            );
          }
          if (field.type === 'textarea') {
            return (
              <TextArea
                key={field.name}
                errorMessages={field.createErrorMessages || field.errorMessages}
                label={field.label}
                name={field.name}
                placeholder={field.label}
                validators={field.createValidators || field.validators}
                value={this.getFieldValue(field.name)}
                width={16}
                onChange={this.handleInputChange}
              />
            );
          }
          if (field.type === 'dropdown') {
            return (
              <Dropdown
                key={field.name}
                selection
                errorMessages={field.createErrorMessages || field.errorMessages}
                label={field.label}
                multiple={field.multiple}
                name={field.name}
                options={isFunction(field.options) ? field.options(options) : field.options}
                placeholder={field.label}
                search={field.search}
                validators={field.createValidators || field.validators}
                value={this.getFieldValue(field.name)}
                width={16}
                onChange={this.handleInputChange}
              />
            );
          }
          return null;
        })}
      </Form>
    );
  }
}

CreateForm.propTypes = {
  createModel: PropTypes.func.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape(schemas.field)).isRequired,
  id: PropTypes.string.isRequired,
  onCreateModel: PropTypes.func,
  onCreateModelFailure: PropTypes.func,
  options: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)))
};

CreateForm.defaultProps = {
  onCreateModel: () => Promise.resolve(),
  onCreateModelFailure: () => Promise.resolve(),
  options: null
};

export default CreateForm;
