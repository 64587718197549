import * as types from '../types';

const initialState = {
  data: [],
  options: [],
  pagination: {
    rowCount: 0
  },
  paginationSettings: {
    page: 1,
    perPage: 10,
    sort: 'id',
    sortDirection: 'ascending'
  }
};

export const UsersReducers = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.FETCH_USERS_SUCCESS:
      return {
        ...state,
        data: action.data,
        pagination: action.pagination,
        paginationSettings: action.paginationSettings
      };

    case types.FETCH_USERS_OPTIONS_SUCCESS:
      return {
        ...state,
        options: action.options
      };

    default:
      return state;
  }
};

export const getUsersData = state => state.users.data;
export const getUsersOptions = state => state.users.options;
export const getUsersPagination = state => state.users.pagination;
export const getUsersPaginationSettings = state => state.users.paginationSettings;
