import React from 'react';
import { Container, Loader, Segment } from 'semantic-ui-react';

const Loading = () => (
  <Container fluid id="wrapper">
    <Segment basic id="content-center">
      <Loader active size="huge">Loading</Loader>
    </Segment>
  </Container>
);

export default Loading;
