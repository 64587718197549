import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import * as articlesActions from '../actions/ArticlesActions';
import * as contentsActions from '../actions/ContentsActions';
import * as tagsActions from '../actions/TagsActions';
import * as actions from '../actions/UsersActions';
import BaseTable from '../components/table/BaseTable';
import Toast from '../components/Toast';
import * as articlesReducers from '../reducers/ArticlesReducers';
import * as contentsReducers from '../reducers/ContentsReducers';
import * as tagsReducers from '../reducers/TagsReducers';
import * as reducers from '../reducers/UsersReducers';
import * as schemas from '../schemas';

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.columns = [
      { label: 'Name', name: 'name', sortable: true },
      { label: 'Username', name: 'username', sortable: true },
      { label: 'Email', name: 'email', sortable: true }
    ];
    this.fields = [
      {
        type: 'text',
        errorMessages: [
          'The name field is required.',
          'The name field may not be greater than 100 characters.'
        ],
        label: 'Name',
        name: 'name',
        validators: ['required', 'maxStringLength:100']
      },
      {
        type: 'text',
        errorMessages: [
          'The username field is required.',
          'The username field may not be greater than 100 characters.'
        ],
        label: 'Username',
        name: 'username',
        validators: ['required', 'maxStringLength:100']
      },
      {
        type: 'password',
        createErrorMessages: [
          'The password field is required.',
          'The password field may not be greater than 100 characters.'
        ],
        createValidators: ['required', 'maxStringLength:100'],
        default: () => '',
        label: 'Password',
        name: 'password',
        prepare: value => (value !== '' ? value : undefined),
        updateErrorMessages: [
          'The password field may not be greater than 100 characters.'
        ],
        updateValidators: ['maxStringLength:100']
      },
      {
        type: 'email',
        errorMessages: [
          'The email field is required.',
          'The email field must be a valid email.',
          'The email field may not be greater than 100 characters.'
        ],
        label: 'Email',
        name: 'email',
        validators: ['required', 'isEmail', 'maxStringLength:100']
      }
    ];
    this.modelName = 'user';
  }

  UNSAFE_componentWillMount() {
    const { data } = this.props;
    if (!data.length) {
      this.fetchUsers();
    }
  }

  fetchUsers = ({ page, perPage, sort, sortDirection } = {}) => {
    const { fetchUsers, paginationSettings } = this.props;
    return fetchUsers({
      page: page || paginationSettings.page,
      perPage: perPage || paginationSettings.perPage,
      sort: sort || paginationSettings.sort,
      sortDirection: sortDirection || paginationSettings.sortDirection
    })
      .catch(() => this.openToastFailure('Users fetching failed'));
  };

  fetchUsersOptions = () => {
    const { fetchUsersOptions } = this.props;
    return fetchUsersOptions()
      .catch(() => this.openToastFailure('Users options fetching failed'));
  };

  fetchArticles = () => {
    const { articlesPaginationSettings, fetchArticles } = this.props;
    return fetchArticles(articlesPaginationSettings)
      .catch(() => this.openToastFailure('Articles fetching failed'));
  };

  fetchContents = () => {
    const { contentsPaginationSettings, fetchContents } = this.props;
    return fetchContents(contentsPaginationSettings)
      .catch(() => this.openToastFailure('Contents fetching failed'));
  };

  fetchTags = () => {
    const { fetchTags, tagsPaginationSettings } = this.props;
    return fetchTags(tagsPaginationSettings)
      .catch(() => this.openToastFailure('Tags fetching failed'));
  };

  openToastFailure = message => toast.error(() => <Toast message={message} />);

  handleCreateUser = () => Promise.all([
    this.fetchUsers(),
    this.fetchUsersOptions()
  ]);

  handleUpdateUser = () => Promise.all([
    this.fetchUsers(),
    this.fetchUsersOptions(),
    this.fetchArticles()
  ]);

  handleDeleteUser = () => Promise.all([
    this.fetchUsers(),
    this.fetchUsersOptions(),
    this.fetchArticles(),
    this.fetchContents(),
    this.fetchTags()
  ]);

  render() {
    const { createUser, data, deleteUser, pagination, paginationSettings, updateUser } = this.props;
    return (
      <BaseTable
        columns={this.columns}
        createModel={createUser}
        data={data}
        deleteModel={deleteUser}
        fetchModels={this.fetchUsers}
        fields={this.fields}
        modelName={this.modelName}
        pagination={pagination}
        paginationSettings={paginationSettings}
        updateModel={updateUser}
        onCreateModel={this.handleCreateUser}
        onDeleteModel={this.handleDeleteUser}
        onUpdateModel={this.handleUpdateUser}
      />
    );
  }
}

Users.propTypes = {
  articlesPaginationSettings: PropTypes.shape(schemas.paginationSettings).isRequired,
  contentsPaginationSettings: PropTypes.shape(schemas.paginationSettings).isRequired,
  createUser: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape(schemas.user)).isRequired,
  deleteUser: PropTypes.func.isRequired,
  fetchArticles: PropTypes.func.isRequired,
  fetchContents: PropTypes.func.isRequired,
  fetchTags: PropTypes.func.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  fetchUsersOptions: PropTypes.func.isRequired,
  pagination: PropTypes.shape(schemas.pagination).isRequired,
  paginationSettings: PropTypes.shape(schemas.paginationSettings).isRequired,
  tagsPaginationSettings: PropTypes.shape(schemas.paginationSettings).isRequired,
  updateUser: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  articlesPaginationSettings: articlesReducers.getArticlesPaginationSettings(state),
  contentsPaginationSettings: contentsReducers.getContentsPaginationSettings(state),
  data: reducers.getUsersData(state),
  pagination: reducers.getUsersPagination(state),
  paginationSettings: reducers.getUsersPaginationSettings(state),
  tagsPaginationSettings: tagsReducers.getTagsPaginationSettings(state)
});

const mapDispatchToProps = dispatch => ({
  createUser: model => actions.createUser(model),
  deleteUser: id => actions.deleteUser(id),
  fetchArticles: pagination => dispatch(articlesActions.fetchArticles(pagination)),
  fetchContents: pagination => dispatch(contentsActions.fetchContents(pagination)),
  fetchTags: pagination => dispatch(tagsActions.fetchTags(pagination)),
  fetchUsers: pagination => dispatch(actions.fetchUsers(pagination)),
  fetchUsersOptions: () => dispatch(actions.fetchUsersOptions()),
  updateUser: (id, model) => actions.updateUser(id, model)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Users);
