import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link, Redirect, Route, Switch } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Container, Grid, Icon, Image, Menu, Segment } from 'semantic-ui-react';
import * as actions from '../actions/AuthActions';
import Toast from '../components/Toast';
import * as reducers from '../reducers/AuthReducers';
import * as schemas from '../schemas';
import Admins from './Admins';
import Articles from './Articles';
import Contents from './Contents';
import Tags from './Tags';
import Users from './Users';

class Basic extends React.Component {
  openToastSuccess = () => toast.success(() => <Toast message="Logout succeeded" />);

  handleLogout = () => {
    const { logout } = this.props;
    this.openToastSuccess();
    logout();
    localStorage.clear();
  };

  render() {
    const { data } = this.props;
    return data ? (
      <Container fluid id="wrapper">
        <Segment inverted vertical id="header">
          <Menu inverted>
            <Menu.Item style={{ padding: '5px 16px' }}>
              <Image src="/logo512.png" style={{ width: '38px', height: '38px' }} />
            </Menu.Item>
            <Menu.Item>Articles App</Menu.Item>
            <Menu.Menu position="right">
              <Menu.Item>{data && data.name}</Menu.Item>
              <Menu.Item>
                <Button basic icon inverted onClick={this.handleLogout}>
                  <Icon name="sign-out" />
                </Button>
              </Menu.Item>
            </Menu.Menu>
          </Menu>
        </Segment>
        <Segment vertical id="content">
          <Grid>
            <Grid.Row>
              <Grid.Column width={3}>
                <Menu fluid vertical>
                  <Link to="/users"><Menu.Item link>Users</Menu.Item></Link>
                  <Link to="/articles"><Menu.Item link>Articles</Menu.Item></Link>
                  <Link to="/contents"><Menu.Item link>Contents</Menu.Item></Link>
                  <Link to="/tags"><Menu.Item link>Tags</Menu.Item></Link>
                  <Link to="/admins"><Menu.Item link>Admins</Menu.Item></Link>
                </Menu>
              </Grid.Column>
              <Grid.Column width={13}>
                <Switch>
                  <Route exact component={Users} path="/" />
                  <Route exact component={Users} path="/users" />
                  <Route exact component={Articles} path="/articles" />
                  <Route exact component={Contents} path="/contents" />
                  <Route exact component={Tags} path="/tags" />
                  <Route exact component={Admins} path="/admins" />
                </Switch>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <Segment inverted vertical id="footer">
          &copy;
          {' '}
          {new Date().getFullYear()}
        </Segment>
      </Container>
    ) : (
      <Redirect to="/login" />
    );
  }
}

Basic.propTypes = {
  data: PropTypes.shape(schemas.user),
  logout: PropTypes.func.isRequired
};

Basic.defaultProps = {
  data: null
};

const mapStateToProps = state => ({
  data: reducers.getAuthData(state)
});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(actions.logout())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Basic);
