import * as types from '../types';

const initialState = {
  data: [],
  pagination: {
    rowCount: 0
  },
  paginationSettings: {
    page: 1,
    perPage: 10,
    sort: 'id',
    sortDirection: 'ascending'
  }
};

export const AdminsReducers = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.FETCH_ADMINS_SUCCESS:
      return {
        ...state,
        data: action.data,
        pagination: action.pagination,
        paginationSettings: action.paginationSettings
      };

    default:
      return state;
  }
};

export const getAdminsData = state => state.admins.data;
export const getAdminsPagination = state => state.admins.pagination;
export const getAdminsPaginationSettings = state => state.admins.paginationSettings;
