import { upperFirst } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { toast } from 'react-toastify';
import { Button, Icon, Modal } from 'semantic-ui-react';
import Toast from '../Toast';

class DeleteModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }

  switchOpen = () => this.setState(state => ({ open: !state.open }));

  openToastSuccess = message => toast.success(() => <Toast message={message} />);

  openToastFailure = message => toast.error(() => <Toast message={message} />);

  handleSubmit = () => {
    const { deleteModel, model } = this.props;
    deleteModel(model.id)
      .then(() => this.handleDeleteModel())
      .catch(() => this.handleDeleteModelFailure());
  };

  handleDeleteModel = () => {
    const { modelName, onDeleteModel } = this.props;
    onDeleteModel()
      .then(() => this.openToastSuccess(`${upperFirst(modelName)} deleting succeeded`));
  };

  handleDeleteModelFailure = () => {
    const { modelName, onDeleteModelFailure } = this.props;
    onDeleteModelFailure()
      .then(() => this.openToastFailure(`${upperFirst(modelName)} deleting failed`));
  };

  render() {
    const { model, modelName } = this.props;
    const { open } = this.state;
    const trigger = (
      <Button basic icon onClick={this.switchOpen}>
        <Icon name="delete" />
      </Button>
    );
    return (
      <Modal open={open} trigger={trigger} onClose={this.switchOpen}>
        <Modal.Header>
          {upperFirst(modelName)}
          {' '}
          deleting
        </Modal.Header>
        <Modal.Content>
          <p>
            Are you sure want to delete
            {` ${modelName} `}
            with id
            {' '}
            {model.id}
            ?
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button color="red" style={{ width: '85px' }} onClick={this.switchOpen}>Cancel</Button>
          <Button color="green" style={{ width: '85px' }} onClick={this.handleSubmit}>OK</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

DeleteModal.propTypes = {
  deleteModel: PropTypes.func.isRequired,
  model: PropTypes.objectOf(PropTypes.any).isRequired,
  modelName: PropTypes.string.isRequired,
  onDeleteModel: PropTypes.func,
  onDeleteModelFailure: PropTypes.func
};

DeleteModal.defaultProps = {
  onDeleteModel: () => Promise.resolve(),
  onDeleteModelFailure: () => Promise.resolve()
};

export default DeleteModal;
