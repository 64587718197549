import { combineReducers } from 'redux';

import { AdminsReducers } from './AdminsReducers';
import { ArticlesReducers } from './ArticlesReducers';
import { AuthReducers } from './AuthReducers';
import { ContentsReducers } from './ContentsReducers';
import { TagsReducers } from './TagsReducers';
import { UsersReducers } from './UsersReducers';

const AppReducers = combineReducers({
  auth: AuthReducers,
  users: UsersReducers,
  articles: ArticlesReducers,
  contents: ContentsReducers,
  tags: TagsReducers,
  admins: AdminsReducers
});

export default AppReducers;
